.Machines .OverviewTable table th:nth-child(1),
.Machines .OverviewTable table td:nth-child(1) {
    min-width: 220px;
    width: 25%
}

.Machines .OverviewTable table th:nth-child(2),
.Machines .OverviewTable table td:nth-child(2) {
    min-width: 120px;
    width: 10%;
}

.Machines .OverviewTable table th:nth-child(3),
.Machines .OverviewTable table td:nth-child(3) {
    min-width: 120px;
    width: 10%;
}

.Machines .OverviewTable table th:nth-child(4),
.Machines .OverviewTable table td:nth-child(4) {
    min-width: 120px;
    width: 15%;
}

.Machines .OverviewTable table th:nth-child(5),
.Machines .OverviewTable table td:nth-child(5) {
    min-width: 180px;
    width: 15%;
}