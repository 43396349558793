.app__box {
    background: var(--color-white);
    border-radius: var(--radius-medium);
    box-shadow: var(--shadow);
    margin-top: 2.75rem;
    padding: .875em;
}

.app__box--wide {
    padding: 1.5rem;
}