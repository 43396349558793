.RephotoModalContent .body,
.RephotoModalContent .footer {
    padding: 1rem;
}

.RephotoModalContent .footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.RephotoModalContent textarea {
    width: 100%;
}