.app {
    min-height: 100vh;
    overflow: hidden;
}

.app__navbar {
    background: var(--gradient-1);
    bottom: 0;
    color: var(--color-white);
    opacity: 0;
    overflow: hidden;
    padding: 5.5rem 2rem 2.5rem 2rem;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform .4s var(--transition);
    width: 100%;
    z-index: 9998;
}

.app__navbar.open {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.app__navbar .logo {
    align-items: center;
    margin-bottom: 2.75rem;
}

.app__body {
    background: var(--color-light);
}

.app__main {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.app__footer {
    margin-top: 2.5rem;
}

@media only screen and (min-width: 80rem) {
    .app {
        display: flex;
    }

    .app__navbar {
        --coef: 1.75;
        --cross-size: 670px;
        --target-size: 1034px;
        opacity: 1;
        padding: 6rem 0 2rem 3rem;
        pointer-events: all;
        position: relative;
        transform: translateY(0);
        width: 24rem;
    }

    .app__navbar::before, .app__navbar::after {
        background-size: cover;
        content: '';
        pointer-events: none;
        position: absolute;
        z-index: -1;
    }

    .app__navbar::before {
        background: var(--img-target);
        bottom: calc(-1 * (var(--target-size) / var(--coef)));
        height: var(--target-size);
        left: calc(-1 * (var(--target-size) / var(--coef)));
        width: var(--target-size);
    }

    .app__navbar::after {
        background: var(--img-cross);
        bottom: calc(-1 * (var(--cross-size) / var(--coef)));
        height: var(--cross-size);
        left: calc(-1 * (var(--cross-size) / var(--coef)));
        width: var(--cross-size);
    }

    .app__navbar .logo {
        margin-bottom: 5.75rem;
        padding-right: 3rem;
    }

    .app__body {
        width: calc(100% - 24rem);
    }

    .app__main {
        padding: 0 2.5rem 1.5rem 2.5rem;
    }
}

@media only screen and (min-width: 100rem) {
    .app__main {
        padding: 0 5rem 1.5rem 5rem;
    }
}