@charset "utf-8";

@import "css/global/settings.css";
@import "css/global/typography.css";
@import "css/global/helpers.css";
@import "css/global/layout.css";

@import "css/app/layout.css";
@import "css/app/box.css";

/*    ------------------    global.form START    ------------------    */
fieldset {
    border: none;
}

label, .label {
    color: var(--color-base);
    cursor: pointer;
    display: block;
    font-size: 1.07rem;
    font-weight: 400;
    line-height: 1.75;
    padding-bottom: .2em;
}

label::selection, .label::selection {
    background: transparent;
    color: inherit;
}

input[type="datetime-local"], input[type="email"], input[type="password"], input[type="text"], select, textarea {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: var(--radius-large);
    font-size: 1.07rem;
    font-weight: 400;
    line-height: var(--element);
    outline: none;
    padding: 0 1.5rem;
    transition: all .3s var(--transition);
    width: 100%;
}

input[type="datetime-local"], input[type="email"], input[type="password"], input[type="text"] {
    height: var(--element);
}

input[type="datetime-local"]:active, input[type="datetime-local"]:focus, input[type="email"]:active, input[type="email"]:focus, input[type="password"]:active, input[type="password"]:focus, input[type="text"]:active, input[type="text"]:focus, textarea:active, textarea:focus {
    border-color: var(--color-main-2);
}

input::placeholder, textarea::placeholder {
    color: rgba(var(--color-rgb-black), .5);
}

select {
    padding: 1.125rem 1.75rem .25rem;
}

textarea {
    height: 6.5rem;
    line-height: 1.75;
    overflow: auto;
    padding-bottom: .75rem;
    padding-top: .75rem;
    resize: vertical;
}

/* Elements */
.input-container {
    margin: 0 0 2rem 0;
    position: relative;
}

/* Checkbox / Radio */
.check-container input[type="checkbox"], .check-container input[type="radio"] {
    height: 30px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 30px;
}

.check-container label {
    font-size: .75rem;
    padding: .5em 0 0 2.5rem;
    position: relative;
    text-align: left;
    text-transform: uppercase;
}

.check-container label:active, .check-container label:focus, .check-container label:hover {
    color: var(--color-main-2);
}

.check-container label::before {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    content: '';
    height: 24px;
    left: 0;
    position: absolute;
    top: 1px;
    transition: all .3s var(--transition);
    width: 24px;
}

.check-container label:active::before, .check-container label:focus::before {
    border-color: var(--color-main-2);
}

.check-container input:checked ~ label::after {
    content: '';
    position: absolute;
}

.check-container input[type="checkbox"] ~ label::before {
    border-radius: var(--radius-small);
}

.check-container input[type="checkbox"]:checked ~ label::after {
    border-color: var(--color-main-2);
    border-style: solid;
    border-width: 0 2px 2px 0;
    height: 12px;
    left: 9px;
    top: 5px;
    transform: rotateZ(45deg);
    width: 6px;
}

.check-container input[type="radio"] ~ label::before {
    border-radius: 50%;
}

.check-container input[type="radio"]:checked ~ label::after {
    background: var(--color-main-2);
    border-radius: 50%;
    height: 12px;
    left: 7px;
    top: 8px;
    width: 12px;
}

@media only screen and (min-width: 40rem) {
    .check-container label {
        padding-top: .3em;
    }
}

/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown select {
    display: none;
}

.dropdown__selection {
    background: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: var(--radius-large);
    cursor: pointer;
    font-size: 1.07rem;
    font-weight: 400;
    line-height: var(--element);
    padding: 0 2.25rem 0 1.5rem;
    position: relative;
    transition: all .3s var(--transition);
    white-space: nowrap;
}

.dropdown__selection.active {
    border-color: var(--color-main-2);
}

.dropdown__selection::before {
    background: transparent;
    border-bottom: 1.5px solid var(--color-aside-2);
    border-left: 1.5px solid var(--color-aside-2);
    content: '';
    height: 8px;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: calc(50% - 7px);
    transform: rotateZ(-45deg);
    transition: all .3s var(--transition);
    width: 8px;
    z-index: 2;
}

.dropdown__selection.active::before {
    top: calc(50% - 1px);
    transform: rotateX(180deg) rotateZ(-45deg);
}

.dropdown__options {
    background: var(--color-main-3);
    border: 1px solid var(--color-white);
    border-radius: var(--radius-medium);
    color: var(--color-white);
    font-size: .875em;
    left: 0;
    line-height: 1.6;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 99;
}

.dropdown__options div {
    cursor: pointer;
    padding: .5rem 1rem;
    user-select: none;
}

.dropdown__options div + div {
    border-top: 1px solid rgba(var(--color-rgb-white), .2);
}

.dropdown__options div:active, .dropdown__options div:focus, .dropdown__options div:hover, .dropdown__options div.selected {
    background: var(--color-aside-3);
}

/*    ------------------    global.form END    ------------------    */


/*    ------------------    global.buttons START    ------------------    */
.button {
    backface-visibility: hidden;
    border: none;
    border-radius: var(--radius-large);
    display: inline-block;
    font-size: 1.07rem;
    font-weight: 400;
    line-height: var(--element);
    min-width: 12rem;
    overflow: hidden;
    padding: 0 1.5rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: all .3s var(--transition) .15s;
    vertical-align: top;
    white-space: nowrap;
    z-index: 1;
}

.button::before {
    bottom: -2px;
    content: '';
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    transform: rotateX(-90deg);
    transition: all .4s var(--transition);
    z-index: -1;
}

.button:active::before, .button:focus::before, .button:hover::before {
    transform: rotateX(0);
}

.button.full {
    width: 100%;
}

.button.icon {
    padding-right: 5.25rem;
}

.button.icon svg {
    height: 28px;
    pointer-events: none;
    position: absolute;
    right: 1.5rem;
    top: calc(50% - 14px);
    transform: all .3s var(--transition) .15s;
    width: 28px;
}

.button.small {
    font-size: 1rem;
    line-height: calc(var(--element) / 1.35);
}

.button.main {
    background: var(--color-main-2);
    background: var(--gradient-1);
    color: var(--color-white);
}

.button.main::before {
    background: var(--color-base);
}

.button.main svg {
    fill: var(--color-white);
}

.button.aside {
    background: var(--color-aside-1);
    background: var(--gradient-2);
    color: var(--color-white);
}

.button.aside::before {
    background: var(--color-main-3);
}

.button.aside svg {
    fill: var(--color-white);
}

.button.primary {
    background: var(--color-aside-3);
    color: var(--color-white);
}

.button.primary::before {
    background: var(--color-main-3);
}

.button.primary svg {
    fill: var(--color-white);
}

.button.secondary {
    background: var(--color-main-3);
    color: var(--color-white);
}

.button.secondary::before {
    background: var(--color-aside-3);
}

.button.secondary svg {
    fill: var(--color-white);
}

.button.base {
    background: var(--color-base);
    color: var(--color-white);
}

.button.base::before {
    background: var(--color-aside-1);
}

.button.base svg {
    fill: var(--color-white);
}

.button.white {
    background: var(--color-white);
    box-shadow: 0 0 0 1px var(--color-gray);
    color: var(--color-base);
}

.button.white:active, .button.white:focus, .button.white:hover {
    color: var(--color-white);
}

.button.white::before {
    background: var(--color-base);
}

.button.white svg {
    fill: var(--color-aside-2);
}

.button.white:active svg, .button.white:focus svg, .button.white:hover svg {
    fill: var(--color-white);
}

.button.white-shadow {
    background: var(--color-white);
    box-shadow: var(--shadow);
    color: var(--color-base);
}

.button.white-shadow:active, .button.white-shadow:focus, .button.white-shadow:hover {
    color: var(--color-white);
}

.button.white-shadow::before {
    background: var(--color-base);
}

.button.white-shadow svg {
    fill: var(--color-main-1);
    color: var(--color-main-1);
}

.button.white-shadow:active svg, .button.white-shadow:focus svg, .button.white-shadow:hover svg {
    fill: var(--color-white);
}

@media only screen and (min-width: 40rem) {
    .button {
        padding: 0 2.5rem;
    }
}

/*    ------------------    global.buttons END    ------------------    */

/*    ------------------    app.controls START    ------------------    */
.app__controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.app__controls__user a {
    background: var(--color-white);
    border-radius: 0 0 0 var(--radius-medium);
    box-shadow: var(--shadow);
    color: var(--color-base);
    display: block;
    font-size: .8125rem;
    font-weight: 300;
    height: 100%;
    line-height: 1.5;
    padding: .75rem 2em .75rem 1.5em;
    text-decoration: none;
}

.app__controls__user a:active, .app__controls__user a:focus, .app__controls__user a:hover {
    background: var(--color-base);
    color: var(--color-white);
}

.app__controls__user a strong {
    color: var(--color-main-1);
    font-size: 1.27em;
    font-weight: 400;
    line-height: 1.1;
    transition: all .3s var(--transition);
}

.app__controls__user a:active strong, .app__controls__user a:focus strong, .app__controls__user a:hover strong {
    color: var(--color-white);
}

.app__controls__touch {
    display: none;
}

.app__controls__menu {
    position: relative;
    z-index: 9999;
}

.app__controls__menu button {
    align-items: center;
    background: var(--color-aside-1);
    background: var(--gradient-2);
    border: none;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-width: 4rem;
    outline: none;
    padding: .25em .75em;
    position: relative;
    transition: all .3s var(--transition);
    z-index: 2;
}

.app__controls__menu button:active, .app__controls__menu button:focus, .app__controls__menu button:hover {
    background: var(--color-main-3);
}

.app__controls__menu button.open {
    background: var(--color-aside-3);
}

.app__controls__menu .burger {
    height: 22px;
    position: relative;
    width: 75%;
}

.app__controls__menu .burger span {
    background: var(--color-white);
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(50% - 1px);
    transition: background .2s var(--transition);
}

.app__controls__menu .open .burger span {
    background: none;
    left: -3px;
}

.app__controls__menu .burger span::before, .app__controls__menu .burger span::after {
    background: var(--color-white);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition-delay: .3s, 0s, 0s;
    transition-duration: .3s, .3s, .2s;
}

.app__controls__menu .burger span::before {
    right: 0;
    top: -6px;
    transition-property: top, transform, background;
}

.app__controls__menu .open .burger span::before {
    left: .625rem;
    top: 0;
    transform: rotate(45deg);
    width: 20px;
}

.app__controls__menu .burger span::after {
    bottom: -6px;
    right: 8px;
    transition-property: bottom, transform, background;
}

.app__controls__menu .open .burger span::after {
    bottom: 0;
    left: .625rem;
    transform: rotate(-45deg);
    width: 20px;
}

.app__controls__menu .stamp {
    font-size: .625rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

@media only screen and (min-width: 80rem) {
    .app__controls__user a {
        font-size: .94rem;
        padding: 2rem 5em 2rem 2.5em;
    }

    .app__controls__touch {
        background: var(--color-main-1);
        background: var(--gradient-1);
        border-radius: 0 0 0 var(--radius-medium);
        cursor: pointer;
        display: block;
        margin-left: calc(-1 * var(--radius-medium));
        padding: 2rem;
    }

    .app__controls__touch svg {
        fill: transparent;
        height: 2.75rem;
        stroke: var(--color-white);
        transition: all .3s var(--transition);
    }

    .app__controls__touch:active svg, .app__controls__touch:focus svg, .app__controls__touch:hover svg {
        stroke: var(--color-aside-1);
    }

    .app__controls__menu {
        display: none;
    }
}

/*    ------------------    app.controls END    ------------------    */

/*    ------------------    app.welcome START    ------------------    */
.app__welcome {
    color: var(--color-slate);
    font-weight: 700;
}

/*    ------------------    app.welcome END    ------------------    */

/*    ------------------    app.title START    ------------------    */
.app__title {
    margin-bottom: 2rem;
}

.app__title h1 {
    margin: 0;
}

.app__title p {
    color: var(--color-slate);
    font-weight: 500;
}

/*    ------------------    app.title END    ------------------    */

/*    ------------------    app.bricks START    ------------------    */
.app__bricks {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
    justify-content: space-between;
    margin-top: 2em;
}

.app__bricks__item {
    background: var(--color-white);
    border-radius: var(--radius-medium);
    box-shadow: var(--shadow);
    padding: 2em 4em 2em 2.5em;
    position: relative;
    width: 100%;
}

.app__bricks__item strong {
    color: var(--color-aside-2);
    font-size: 2.25em;
    font-weight: 400;
    line-height: 1.05;
}

.app__bricks__item svg {
    height: 24px;
    fill: transparent;
    position: absolute;
    right: 2rem;
    stroke: var(--color-gray);
    stroke-linecap: round;
    stroke-width: 12px;
    top: calc(50% - 12px);
}

.app__bricks__item:nth-child(2) {
    background: var(--gradient-2);
    color: var(--color-white);
}

.app__bricks__item:nth-child(3) {
    background: var(--gradient-1);
    color: var(--color-white);
}

.app__bricks__item:nth-child(2) strong, .app__bricks__item:nth-child(3) strong {
    color: var(--color-white);
}

.app__bricks__item:nth-child(2) svg, .app__bricks__item:nth-child(3) svg {
    stroke: var(--color-white);
}

@media only screen and (min-width: 32rem) {
    .app__bricks__item {
        width: calc(50% - .375rem);
    }
}

@media only screen and (min-width: 60rem) {
    .app__bricks__item {
        width: calc(25% - .5625rem);
    }
}

@media only screen and (min-width: 100rem) {
    .app__bricks__item strong {
        font-size: 2.95em;
    }
}

/*    ------------------    app.bricks END    ------------------    */

/*    ------------------    app.filter START    ------------------    */
.app__filter {
    background: var(--color-light);
    border-radius: var(--radius-large);
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
    padding: 1rem 1.125rem;
}

.app__filter__item {
    flex-grow: 1;
    position: relative;
}

.app__filter__item label {
    left: 0;
    line-height: var(--element);
    padding: 0 4rem 0 1.5rem;
    position: absolute;
    top: 0;
    transition: all .3s var(--transition);
    z-index: 2;
}

.app__filter__item.mini label {
    font-size: .75rem;
    line-height: 1.1;
    opacity: .75;
    top: .4rem;
}

.app__filter__item svg {
    fill: transparent;
    height: 24px;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    stroke: var(--color-aside-2);
    stroke-width: 2px;
    top: calc(50% - 12px);
}

.app__filter__item.ico input[type="datetime-local"], .app__filter__item.ico input[type="email"], .app__filter__item.ico input[type="text"] {
    padding-right: 3.25rem;
}

/*    ------------------    app.filter END    ------------------    */

/*    ------------------    app.table START    ------------------    */
.app__table {
    margin-top: 1.5rem;
    padding: 0 1.5em;
}

.app__table__header {
    font-size: .94rem;
    font-weight: 300;
    line-height: 1.7;
}

.app__table__header strong {
    font-weight: 400;
}

.app__table__body {
    font-size: .94rem;
    margin-bottom: 2.5rem;
    overflow-x: auto;
    padding-bottom: 1em;
    white-space: nowrap;
}

.app__table__body::-webkit-scrollbar {
    width: .9em;
}

.app__table__body::-webkit-scrollbar-thumb {
    background: var(--color-main-1);
    background: var(--gradient-1);
    border-radius: var(--radius-medium);
}

.app__table__body::-webkit-scrollbar-track {
    border-radius: var(--radius-medium);
    box-shadow: inset 0 0 5px rgba(var(--color-rgb-black), .25);
}

.app__table__body td {
    border-top: 1px solid var(--color-gray);
    color: var(--color-main-1);
    font-weight: 300;
    padding: .75rem 1em;
    vertical-align: middle;
}

.app__table__body td img {
    aspect-ratio: 1 / 1;
    display: block;
}

.app__table__body td a img {
    transition: all .3s var(--transition);
}

.app__table__body td a:active img, .app__table__body td a:focus img, .app__table__body td a:hover img {
    transform: scale(1.15);
}

.app__table__body th {
    font-weight: 400;
    padding: .625rem 1em;
    text-transform: uppercase;
}

.app__table__body th:first-child {
    padding-left: 0;
}

.app__table__body th:last-child {
    padding-right: 0;
}

@media only screen and (min-width: 40rem) {
    .app__table__body {
        margin-bottom: 4rem;
    }
}

/*    ------------------    app.table END    ------------------    */

/*    ------------------    app.detail START    ------------------    */
.app__detail {
    padding: 0 1.5rem 2.5rem;
}

.app__info table td, .app__info table th {
    font-size: .875rem;
    font-weight: 300;
    line-height: 1.6;
    padding: .5rem 1em;
    width: 50%;
}

.app__info table tr:nth-child(2n+1) td, .app__info table tr:nth-child(2n+1) th {
    background: rgba(var(--color-rgb-black), .03);
}

.app__detail__side {
    margin: 3rem auto 0;
    max-width: 24rem;
    text-align: center;
}

@media only screen and (min-width: 60rem) {
    .app__detail {
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 0 0 0 3.5rem;
    }

    .app__detail__main {
        padding-bottom: 2.75rem;
        width: calc(100% - 29rem);
    }

    .app__detail__side {
        background: var(--color-white);
        border-radius: var(--raduis-medium) 0 0 0;
        box-shadow: var(--shadow);
        margin: 4rem 0 0;
        max-width: none;
        padding: 2.5rem 2.25rem 3rem 2.25rem;
        width: 24.5rem;
    }

    .app__info table td, .app__info table th {
        font-size: 1rem;
    }
}

@media only screen and (min-width: 100rem) {
    .app__detail {
        padding-left: 5.5rem;
    }

    .app__info {
        display: flex;
        gap: 3.25rem;
        flex-direction: column;
    }

    .sub__container {
        display: flex;
        gap: 3.25rem;
        justify-content: space-between;
    }

    .app__info__item {
        flex-grow: 1;
    }

    .app__info table td, .app__info table th {
        width: auto;
    }
}

/*    ------------------    app.detail END    ------------------    */

/*    ------------------    app.360 START    ------------------    */
.app__360 {
    height: 31.25rem;
    margin: 1rem 0;
    max-height: 95vh;
    position: relative;
}

@media only screen and (min-width: 60rem) {
    .app__360 {
        margin: 2rem 0;
    }
}

/*    ------------------    app.360 END    ------------------    */