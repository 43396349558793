.EditPhotoModalContent .body,
.EditPhotoModalContent .footer {
    padding: 1rem;
}

.EditPhotoModalContent .footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.EditPhotoModalContent textarea {
    width: 100%;
}

.EditPhotoModalContent .FormText {
    margin-bottom: 16px;
}

.ReactModal__Overlay--after-open {
    z-index: 100;
}

.editPhotoModal .ReactModal__Content.ReactModal__Content--after-open {
    height: 320px;
}