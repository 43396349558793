.MachineSelector {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1rem;
    align-items: start;
}

@media only screen and (min-width: 40rem) {
    .MachineSelector {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 80rem) {
    .MachineSelector {
        grid-template-columns: repeat(3, 1fr);
    }
}