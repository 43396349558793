.PhotoDetailControlSection .section {
    margin-top: 16px;
}

.PhotoDetailControlSection button {
    width: 250px;
    margin-right: 16px;
}

.PhotoDetailControlSection label {
    font-size: 22px;
}

.PhotoDetailControlSection .container {
    margin-top: 8px;
}

.PhotoDetailControlSection .container .FormFile {
    display: inline-block;
    margin-right: 16px;
}