.Overview .annotation label {
    color: #B7BAC1;
    font-size: 14px;
}

.Overview .annotation h1 {
    color: #1B2338;
    margin: 10px 0 32px 0;
}

.Overview .bricks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
}

.Overview .bricks .OverviewBrick:nth-child(2) .label,
.Overview .bricks .OverviewBrick:nth-child(2) .value,
.Overview .bricks .OverviewBrick:nth-child(2) svg,
.Overview .bricks .OverviewBrick:nth-child(3) .label,
.Overview .bricks .OverviewBrick:nth-child(3) .value,
.Overview .bricks .OverviewBrick:nth-child(3) svg {
    color: #ffffff;
}

.Overview .bricks .OverviewBrick:nth-child(2) {
    background: linear-gradient(90deg, rgb(96, 87, 166) 0%, rgb(87, 54, 133) 100%);
    color: #ffffff;
}

.Overview .bricks .OverviewBrick:nth-child(3) {
    background-color: #1B2338;
}
