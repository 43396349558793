.Photos .Photos-item-row.checked td {
    background-color: rgba(196, 255, 189, .3);
}

.Photos .Photos-item-row.rephoto td {
    background-color: rgba(255, 187, 96, .3);
}

.Photos .csv-icon {
    fill: var(--color-aside-2);
    height: 32px;
    cursor: pointer;
    margin-left: 1rem;
}

.Photos .csv-icon.isLoading {
    cursor: wait;
}

.Photos .app__table__header {
    display: flex;
    align-items: center;
}