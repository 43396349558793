.OverviewBrick {
    box-shadow: -6px 6px 20px 0 rgba(14, 30, 37, .2);
    padding: 24px 32px;
    border-radius: 14px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 54px;
}

.OverviewBrick.pointer * {
    cursor: pointer;
}

.OverviewBrick > div {
    display: flex;
    flex-direction: column;
}

.OverviewBrick .label {
    color: #1B2338;
    font-size: 14px;
}

.OverviewBrick .value {
    color: #613688;
    font-size: 32px;
}

.OverviewBrick svg {
    color: #D1D1D9;
}