.DownloadFullModalContent .body,
.DownloadFullModalContent .footer {
    padding: 1rem;
}

.DownloadFullModalContent .footer {
    display: flex;
    justify-content: space-between;
}

.DownloadFullModalContent textarea {
    width: 100%;
}

div.ReactModal__Content.ReactModal__Content--after-open {
    height: 300px;
    width: 500px;
}