.PhotoDetail .Overview .customLabel {
    color: #1B2338;
    margin: 10px 0 32px 0;
}

.PhotoDetail .Overview .customLabel .icon {
    margin-left: 16px;
    cursor: pointer;
}

.PhotoDetail .spacing button {
    margin-right: 1rem;
}

.PhotoDetail .Error {
    margin-top: 2.5rem;
}