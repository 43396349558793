.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.mb-3 {
    margin-bottom: 3em;
}

.mb-4 {
    margin-bottom: 4em;
}

.mt-1 {
    margin-top: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.mt-3 {
    margin-top: 3em;
}

.mt-4 {
    margin-top: 4em;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-wrap {
    white-space: normal;
}

.sprite {
    height: 0;
    width: 0;
}

[hidden], .hide {
    display: none;
}