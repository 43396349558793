.MachineForm .formContent {
    display: flex;
}

.MachineForm .formContent form {
    width: 100%;
}

.MachineForm .formContent form > .customGrid,
.MachineForm .formContent form .grid div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-bottom: 16px;
}

.MachineForm .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.MachineForm .userDefinedInput {
    font-size: 24px;
}

.MachineForm .formContent .divider {
    background: var(--color-aside-2);
}

.MachineForm .formContent h4 {
    margin-top: 96px;
    color: var(--color-aside-2);
}