.AppConfig .AppConfigOverview {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.AppConfig .AppConfigOverview p {
    font-size: 22px;
}

.AppConfig .AppConfigOverview .row {
    margin-bottom: 12px;
    display: flex;
}

.AppConfig .AppConfigOverview .row label:first-child {
    width: 250px;
}

.AppConfigOverview .userList .choices {
    display: flex;
    flex-wrap: wrap;
}

.AppConfigOverview .userList .choices input {
    margin-right: 1rem;
}

.AppConfigOverview .notification-table tr:not(:last-child),
.AppConfigOverview .notification-table thead tr {
    border-bottom: 1px solid var(--color-gray);
}

.AppConfigOverview .notification-table td:nth-child(1),
.AppConfigOverview .notification-table td:nth-child(5) {
    width: 200px;
}

.AppConfigOverview .notification-table td:nth-child(2) {
    width: 70px;
}

.AppConfigOverview .notification-table td:nth-child(3) {
    width: 50px;
}

.AppConfigOverview .notification-table thead th:nth-child(4),
.AppConfigOverview .notification-table td:nth-child(4) {
    padding: 0 1rem;
}