.NestedForm > p {
    border-bottom: 2px solid gray;
    font-size: 18px;
    margin-top: 40px;
}

.NestedForm .desc {
    position: relative;
    top: -18px;
    font-size: 13px;
}