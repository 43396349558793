.GpuForm {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.GpuForm .formContent {
    display: flex;
}

.GpuForm .formContent form {
    width: 100%;
}

.GpuForm .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.GpuForm .formContent form > .customGrid,
.GpuForm .formContent form .grid > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    margin-bottom: 16px;
}

.GpuForm .formContent .colorSettings > label {
    font-size: 26px;
}

.GpuForm .FormCheckbox input {
    margin-top: .5rem;
}

.GpuForm .FormCheckbox {
    text-transform: uppercase;
    margin-bottom: auto;
}

.GpuForm .formContent .divider {
    background: var(--color-aside-2);
}

.GpuForm .formContent h4 {
    margin-top: 96px;
    color: var(--color-aside-2);
}