.accordion {
    border: 1px solid #ccc;
    margin-bottom: 1em;
}

.accordion-header {
    display: flex;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;
    background-color: #f0f0f0;
}

.accordion-header-checkbox {
    margin-right: 0.5em;
}

.accordion-header-arrow {
    margin-left: auto;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    transition: transform 0.2s;
}

.accordion-header-arrow span {
    display: block;
    width: 100%;
    height: 100%;
    border-left: 1px solid #333;
    border-top: 1px solid #333;
}

.accordion-header-arrow-open {
    transform: rotate(225deg);
}

.accordion-content {
    display: none;
    padding: 0.5em;
}

.accordion-content > div {
    display: flex;
}

.accordion-content-open {
    display: block;
}

.accordion-checkbox {
    margin-right: 0.5em;
}

.accordion-label {
    padding: 0;
}