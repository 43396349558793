.Select {
    position: relative;
}

.Select .empty__option {
    min-height: 40px;
}

.Select .label__placeholder {
    min-height: 34px;
    line-height: var(--element);
    padding-bottom: 0;
}