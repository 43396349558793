.ClientForm {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

.ClientForm .formContent {
    display: flex;
}

.ClientForm .formContent form {
    width: 100%;
}

.ClientForm .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.ClientForm .formContent form > .customGrid,
.ClientForm .formContent form .grid > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    margin-bottom: 16px;
}

.ClientForm .formContent .colorSettings > label {
    font-size: 26px;
}

.ClientForm .FormCheckbox input {
    margin-top: .5rem;
}

.ClientForm .FormCheckbox {
    text-transform: uppercase;
    margin-bottom: auto;
}

.ClientForm .formContent .divider {
    background: var(--color-aside-2);
}

.ClientForm .formContent h4 {
    margin-top: 96px;
    color: var(--color-aside-2);
}