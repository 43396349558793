.FormList .list .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.FormList .list .list-item svg {
    fill: var(--color-aside-2);
    height: 3rem;
    cursor: pointer;
}

.FormList .list .list-item .FormText {
    width: 100%;
}

.FormList p {
    border-bottom: 2px solid gray;
    font-size: 18px;
    margin-top: 40px;
}

.FormList .actions {
    margin-top: 1rem;
}