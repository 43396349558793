html { font-family: sans-serif; overflow-y: scroll; scroll-behavior: smooth; text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none; -webkit-text-size-adjust: none; }

body, button, .button, input, select, textarea { font-family: var(--font-main); font-kerning: normal; text-rendering: optimizeLegibility; }

a, button, .button, input[type="submit"], select { cursor: pointer; }

body { background: var(--color-white); color: var(--color-base); font-size: 1.07rem; font-weight: 400; line-height: 1.75; scrollbar-color: var(--color-aside-1) var(--color-white); scrollbar-width: thin; text-align: center; }
body::-webkit-scrollbar { width: .85em; }
body::-webkit-scrollbar-thumb { background: var(--color-aside-1); background: var(--gradient-2); border-left: 1px solid var(--color-white); border-radius: var(--radius-medium); }
body::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgba(var(--color-rgb-black),.25); }

a { color: var(--color-main-1); text-decoration: underline; transition: all .3s var(--transition); }
a:active, a:focus, a:hover { text-decoration: none; }
a img { border: none; }

b, strong { font-weight: 700; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 { font-weight: 400; line-height: 1.2; }
h1, .h1, h2, .h2 { font-size: 1.875rem; margin: 1.5em auto .33em; }
h1:first-child, .h1:first-child, h2:first-child, .h2:first-child { margin-top: 0; }
h3, .h3 { font-size: 1.4375rem; margin: 1em auto .5em; }
h2 + h3, h2 + .h3, .h2 + h3, .h2 + .h3 { margin-top: -.4em; }
h4, .h4 { font-size: 1.25rem; margin: .5em auto .33em; }

p { margin: 0 auto 1rem 0; }
p:last-child { margin-bottom: 0; }

p + ol, p + ul { margin-top: -.5em; }

ol, ul { margin: .25em auto 1em 1.5em; }

sub, sup { font-size: .75em; }
sub { bottom: -.2em; position: relative; vertical-align: bottom; }
sup { position: relative; top: -.4em; vertical-align: top; }

table { border-collapse: collapse; width: 100%; }

@media only screen and (min-width: 40rem) {
	h1, .h1, h2, .h2 { font-size: 2.5rem; }
	h3, .h3 { font-size: 1.75rem; }
	h4, .h4 { font-size: 1.375rem; }
}

@media only screen and (min-width: 80rem) {
	h1, .h1, h2, .h2 { font-size: 3.125rem; }
	h3, .h3 { font-size: 1.875rem; }
	h4, .h4 { font-size: 1.4375rem; }
}