.app__nav ul {
    list-style: none;
    margin: 0;
}

.app__nav li + li {
    margin-top: .7em;
}

.app__nav a {
    background: var(--color-main-3);
    border-radius: var(--radius-giant);
    color: var(--color-white);
    display: block;
    padding: 1em 2.5em 1em 5.2em;
    position: relative;
    text-decoration: none;
}

.app__nav a:active, .app__nav a:focus, .app__nav a:hover {
    background: var(--color-base);
}

.app__nav a.selected {
    background: var(--color-light);
    color: var(--color-base);
}

.app__nav a svg {
    fill: var(--color-aside-1);
    height: 3rem;
    left: 1.75rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    width: 2.5rem;
}

@media only screen and (min-width: 80rem) {
    .app__nav a {
        border-radius: var(--radius-giant) 0 0 var(--radius-giant);
    }
}