.login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

.login__main {
    padding: 4rem 2rem 3rem;
}

.login__main h1 {
    font-weight: 300;
    margin: 0;
}

.login__main .logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
}

.login__form {
    margin-top: 2rem;
}

.login__form .input-container label {
    padding-left: .75em;
}

.login__options {
    margin-bottom: 2rem;
}

.login__options .remember {
    display: inline-block;
    margin-bottom: 1rem;
}

.login__options .forgotten button {
    background: transparent;
    border: none;
    color: var(--color-main-2);
    font-size: 1rem;
    font-weight: 700;
}

.login__options .forgotten button:active, .login__options .forgotten button:focus, .login__options .forgotten button:hover {
    color: var(--color-aside-1);
}

.login__desc {
    background: var(--gradient-1);
    color: var(--color-white);
    flex-grow: 1;
    font-weight: 300;
    padding: 3rem 2rem 2rem;
    overflow: hidden;
}

.login__desc__title {
    margin-bottom: 1.5em;
    position: relative;
    z-index: 2;
}

.login__desc__title h2 {
    font-weight: 300;
}

.login__desc__footer {
    font-size: .875rem;
}

.login__desc__footer a {
    color: var(--color-white);
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
}

.login__desc__footer a:active, .login__desc__footer a:focus, .login__desc__footer a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}

.login__desc__footer p {
    margin-bottom: .4em;
}

.login__desc__links {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.15em;
    gap: 1.5rem;
    justify-content: center;
}

.login__desc__img {
    content-visibility: hidden;
    display: none;
}

@media only screen and (min-width: 40rem) {
    .login__main h1 {
        font-size: 2.5rem;
    }

    .login__options {
        align-items: center;
        display: flex;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .login__options .remember {
        display: block;
        margin-bottom: 0;
    }

    .login__desc__title {
        font-size: 1.41em;
    }
}

@media only screen and (min-width: 60rem) {
    .login {
        flex-direction: row;
        text-align: left;
    }

    .login__main {
        width: 40%;
    }

    .login__main .logo {
        align-items: flex-start;
    }

    .login__desc {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        padding: 4rem 3rem 3rem;
        position: relative;
        width: 60%;
    }

    .login__desc__footer {
        margin-top: auto;
        position: relative;
        z-index: 2;
    }

    .login__desc__links {
        gap: 3rem;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 80rem) {
    .login__desc__img {
        --cross-size: 788px;
        --target-size: 1100px;
        content-visibility: visible;
        display: block;
        position: absolute;
        right: 3rem;
        top: calc(50% - 15rem);
        z-index: 1;
    }

    .login__desc__img::before, .login__desc__img::after {
        background-size: cover;
        content: '';
        position: absolute;
        z-index: -1;
    }

    .login__desc__img::before {
        background: var(--img-target);
        height: var(--target-size);
        left: calc(50% - (var(--target-size) / 2));
        top: calc(50% - (var(--target-size) / 2));
        width: var(--target-size);
    }

    .login__desc__img::after {
        animation: spin 30s linear infinite;
        background: var(--img-cross);
        height: var(--cross-size);
        left: calc(50% - (var(--cross-size) / 2));
        top: calc(50% - (var(--cross-size) / 2));
        width: var(--cross-size);
    }

    .login__desc__img img {
        aspect-ratio: 514 / 480;
        height: 30rem;
    }

    @keyframes spin {
        from {
            transform: rotateZ(0);
        }
        to {
            transform: rotateZ(360deg);
        }
    }
}

@media only screen and (min-width: 100rem) {
    .login__main {
        padding: 4rem 6rem;
    }

    .login__desc {
        padding: 16rem 8rem 7rem;
    }
}