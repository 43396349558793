.FormDropdown {
    position: relative;
}

/*.dropdown-options {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    top: 52px;*/
/*    left: 0;*/
/*    position: absolute;*/
/*}*/

.FormDropdown-container {
    position: absolute;
    transform: translateX(-100%);
    background: #ffffff;
    box-shadow: -6px 6px 20px 0 rgba(14, 30, 37, .2);
    padding: 4px 0;
}

.FormDropdown-container span {
    padding: 4px 16px;
    display: flex;
    flex-direction: column;
    line-height: normal;
}

.FormDropdown-container span:hover {
    background: gray;
}
