.UserDefinedInputsForm .userDefinedRow {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 32px;
    align-items: center;
    margin-top: 40px;
}

.UserDefinedInputsForm .userDefinedRow > svg {
    margin: 16px 16px 0 16px;
    cursor: pointer;
}

.UserDefinedInputsForm p {
    border-bottom: 2px solid gray;
    font-size: 18px;
}